<template>
  <div id="movementSearchView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>search movements</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row mb-1">
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left text-secondary">location</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.locationId">
                <option value="">All</option>
                <option v-for="location in terminalLocations" :value="location.id">{{ location.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left text-secondary">vessel</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.vesselId">
                <option value="">All</option>
                <option v-for="vessel in vessels" :value="vessel.id">{{ vessel.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left text-secondary">barge</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.bargeId">
                <option value="">All</option>
                <option v-for="barge in barges" :value="barge.id">{{ barge.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left text-secondary">user group</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.userGroupId">
                <option value="">All</option>
                <option v-for="userGroup in userGroups" :value="userGroup.id">{{ userGroup.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left text-secondary">mode</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.mode">
                <option value="">All</option>
                <option v-for="mode in Enums.MovementModes" :value="mode.value">{{ mode.description }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left text-secondary">reason</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.reason">
                <option value="">All</option>
                <option v-for="reason in Enums.MovementReasons" :value="reason.value">{{ reason.description }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left text-secondary">Status</label>
              <select class="col-md-5 mr-0 form-control" v-model="searchParams.status">
                <option value="">All</option>
                <option v-for="status in Enums.MovementStatus" :value="status.value">{{ status.description }}</option>
              </select>
            </div>
          </div>

          <div class="col-md-4 mr-0">
            <div class="row">
              <label class="col-md-4 mr-0 text-left text-secondary">charge code</label>
              <input type="text" class="col-md-5 mr-0 form-control" v-model="searchParams.chargeCode" />
            </div>
          </div>

        </div>
        <div class="form-group row text-center">
          <div class="col-sm-12 mr-0">
            <button class="button btn bttn btn-primary" @click.prevent="search">Search</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="result && result.length">
      <div class="col">
        <div>
          <MovementList ref="searchedMovementList" :tableProps="tableProps" :id="'searchedMovementList'+ currentTableKeyIndex" :key="'searchedMovementList-'+ currentTableKeyIndex" :movements="result" emptyTableMessage="There are no movements that match the search criteria" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>

  import store from '@/store'
  import { mapState, mapGetters } from 'vuex'

  import { toStatusDescription } from '@/filters/enum'

  import Enums from '@/utils/enums'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import { FETCH_LOCATIONS, FETCH_VESSELS, FETCH_BARGES, FETCH_USERGROUPS } from '@/store/action-type'

  import { reportService } from "@/services"

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import MovementList from '@/components/reports/MovementList'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'MovementSearchView',
    components: {
      AppAlert,
      AppModal,
      MovementList
    },
    mixins: [AlertMixin, ErrorMixin],
    data: () => ({
      searchParams: {
        locationId: '',
        vesselId: '',
        bargeId: '',
        userGroupId: '',
        mode: '',
        reason: '',
        status: '',
        chargeCode: ''
      },
      result: [],
      currentTableKeyIndex: 1,
      tableProps: {
        order: null,
        page: null,
        layout: 'list'
      },
      Enums: Enums
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        locations: state => state.locationModule.locations,
        vessels: state => state.vesselModule.vessels,
        barges: state => state.bargeModule.barges,
        userGroups: state => state.userGroupModule.userGroups
      }),
      ...mapGetters([
        'terminalLocations',
      ]),
    },
    methods: {
      resetData() {
        this.searchParams.locationId = ''
        this.searchParams.vesselId = ''
        this.searchParams.bargeId = ''
        this.searchParams.userGroupId = ''
      },
      resetTableData() {
        this.result.splice(0, this.result.length)
      },
      search() {
        const self = this
        self.resetTableData();
        self.closeMessage();
        eventBus.$emit(Events.LongOperationStarted, '')
        reportService.search(self.searchParams).then((result) => {
          if (result && result.items.length) {
            self.result = [...result.items]
            self.$nextTick(() => {
              self.$refs.searchedMovementList.refresh();
            });
          }
          else {
            self.showErrorMessage("There are no movements that match the search criteria");
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      }
    },
    filters: {
      toStatusDescription
    },
    mounted() {
      this.search(); // Call the search method when the component is loaded
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading data')
      const tasks = []
      tasks.push(store.dispatch(FETCH_LOCATIONS))
      tasks.push(store.dispatch(FETCH_VESSELS))
      tasks.push(store.dispatch(FETCH_BARGES))
      tasks.push(store.dispatch(FETCH_USERGROUPS))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    beforeRouteUpdate(to, from, next) {
      this.tableProps.order = null;
      this.tableProps.page = null;
      this.tableProps.layout = null;
      this.closeMessage();
      next();
    },
    beforeDestroy() {
    }
  }
</script>
