import Enums from '@/utils/enums'

export const toStatusDescription = (status) => {
  status = status ? 1 : 0
  return Enums.convertToEnum(Enums.Status, status).description
}

export const toLeaveTypeDescription = (leaveType) => {
  leaveType = leaveType ? leaveType : ''
  return Enums.convertToEnum(Enums.RequestType, leaveType).description
}

export const toRequestStatusDescription = (status) => {
  if (status== null)
    return ''
  return Enums.convertToEnum(Enums.RequestStatus, status).description
}

export const toMovementStatusDescription = (status) => {
  if (status == null)
    return 'not yet entered'
  return Enums.convertToEnum(Enums.MovementStatus, status).description
}

export const toMovementModeDescription = (mode) => {
  return Enums.convertToEnum(Enums.MovementModes, mode).description
}

export const toRoleRightsDescription = (rights) => {
  var rightsDescriptions = []
  for (let right in Enums.RoleRights) {
    if (rights & Enums.RoleRights[right].value) {
      rightsDescriptions.push(Enums.RoleRights[right].description)
    }
  }
  return rightsDescriptions.join(', ')
}
