export const toNumericDisplay = (value) => {
  return (value || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

//export const toWholeNumberDisplay = (value) => {
//  return Math.round(value || 0).toLocaleString()
//}

export const toWholeNumberDisplay = (value) => {
  return (Math.round(value) || 0).toLocaleString()
}

export const toNumericDisplayWithNoFractionalLimit = (value) => {
  return (value || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })
}
export const toDateAtTime = (value) => {
  if (value) {
    const date = new Date(Date.parse(value))
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
  }
  return ''
}
export const onlyTime = (value) => {
  if (value) {
    const date = new Date(Date.parse(value))
    const options = { hour: 'numeric', minute: 'numeric' }
    return date.toLocaleTimeString('en-GB', options)
  }
  return ''
}
