<template>
  <transition name="modal">
    <div class="modal-mask" v-show="show" @click="staticClose" :style="{ 'z-index': zIndex }">
      <!--<div class="modal-container" @click.stop>-->
      <div :id="id" class="modal-container" v-bind:class="size">
        <div class="modal-header">
          <slot name="header">
            <h3 v-if="title">{{ title }}</h3>
            <span class="font-weight-bold close-bttn" @click="close">X</span>
          </slot>
        </div>
        <div class="modal-body-footer-container">
          <div class="modal-body">
            <slot>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">

  .close-bttn {
    font-size: 20px;
    cursor: pointer
  }

  .modal-mask {
    position: fixed;
    /*z-index: 9995;*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }

  /*.topMost{
     z-index: 9998;
  }

  .notTopMost{
     z-index: 9997;
  }*/

  .modal-container {
    margin: 40px auto 0;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-container.small {
    width: 30%;
    //height: 60%;
  }

  .modal-container.medium {
    width: 60%;
    //height: 70%;
  }

  .modal-container.large {
    width: 80%;
    //height: 90%;
  }

  .modal-container.xLarge {
    width: 100%;
    margin: 0 0;
    padding: 0 0;
    /*    height: 100%;
*/
  }

 /* .modal-container > .modal-header {
    height: 10%
  }

  .modal-container > .modal-body {
    height: 60%
  }

  .modal-container > .modal-footer {
    height: 30%
  }*/

  .modal-header h3 {
    margin-top: 0;
    color: #0066b2;
  }

  .modal-body {
    margin: 20px 0;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }

  .text-right {
    text-align: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>

<script>
  import eventBus from '@/utils/eventBus'
  import { CLOSE_MODAL_EVENT } from '@/utils/constants'

  export default {
    name: 'app-modal',
    props: {
      id: {
        type: String,
        required: false,
        default: 'modal'
      },
      show: {
        type: Boolean,
        required: false,
        default: false
      },
      closeOnEscape: {
        type: Boolean,
        required: false,
        default: true
      },
      static: {
        type: Boolean,
        required: false,
        default: true
      },
      title: {
        type: String,
        required: false,
        default: ''
      },
      size: {
        type: String,
        required: false,
        default: 'medium',
        validator: function (value) {
          return ['small', 'medium', 'large', 'xLarge'].indexOf(value) !== -1
        }
      },
      closeModalEvent: {
        type: String,
        required: false,
        default: CLOSE_MODAL_EVENT
      }
      //,
      //topMostDialog: {
      //  type: Boolean,
      //  required: false,
      //  default: true
      //}
    },
    data() {
      return {
        zIndex: 9995
      }
    },
    computed: {
      shouldCloseOnEscape() {
        return this.show && this.closeOnEscape && !this.static
      },
    },
    methods: {
      close() {
        eventBus.$emit(this.closeModalEvent)
      },
      staticClose(evt) {
        if (!this.static) {
          this.close()
        }
      },
      escapeClose(e) {
        const self = this
        if (self.shouldCloseOnEscape && e.keyCode == 27) {
          self.close()
        }
      }
    },
    mounted: function () {
      document.addEventListener('keydown', this.escapeClose)
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.escapeClose)
    }
  }
</script>
