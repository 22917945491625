<template>
  <div>
    <table :id="id" class="display table table-condensed" style="width:100% !important; border-collapse:collapse;">
      <thead>
        <tr>
          <th width="15%">Vessell</th>
          <th width="20%">departure date</th>
          <th width="15%">from</th>
          <th width="15%">to</th>
          <th width="15%">mode</th>
          <th width="15%">charge code</th>
          <th width="15%">barge</th>
          <th width="15%">statuts</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(movement, index) in movements">
          <tr>
            <td>{{ movement.vessel.name }}</td>
            <td>{{ movement.departureDate | toDateAtTime }}</td>
            <td>{{ movement.fromLocationName  }}</td>
            <td>{{ movement.toLocationName }}</td>
            <td>{{ movement.mode | toMovementModeDescription }}</td>
            <td>{{ movement.chargeCode }}</td>
            <td>
              <span v-if="movement.barge && movement.barge.name">{{ movement.barge.name }}</span>
              <span v-else>___</span>
            </td>
            <td>{{ movement.status | toMovementStatusDescription }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss">
  .action-btn {
    cursor: pointer;
    max-width: 40% !important;
  }

  /*table tbody tr:nth-child(odd) td {
    background-color: #ffffff !important;
  }*/

  tr.group {
    background-color: #f3f4f8 !important;
  }

</style>

<script>
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'

  import { toDateAtTime } from '@/filters'
  import { toStatusDescription, toMovementStatusDescription, toMovementModeDescription } from '@/filters/enum'

  import eventBus from '@/utils/eventBus'
  import { EDIT_MOVEMENT_EVENT, CANCEL_MOVEMENT_EVENT } from '@/utils/constants'


  export default {
    name: 'movement-list',
    components: {
    },
    props: ['id', 'movements', 'tableProps', 'emptyTableMessage'],
    data: () => ({
      Enums: Enums,
      tableObject: null,
      toMovementStatusDescription
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser
      }),
    },
    watch: {
      //movements: {
      //  handler(newMovements) {
      //    const self = this
      //    self.$nextTick(() => {
      //      self.refresh();
      //    });
      //  },
      //  immediate: true
      //},
    },
    filters: {
      toStatusDescription,
      toMovementStatusDescription,
      toMovementModeDescription,
      toDateAtTime
    },
    methods: {
      refresh() {
        const self = this;
        if (self.tableObject) {
          self.tableObject.destroy();
          $(`#${self.id}`).empty()
        }
        var groupColumn = 0;
        self.tableObject = $(`#${self.id}`).DataTable({
          language: {
            emptyTable: self.emptyTableMessage
          },
          searching: true,
          search: {
            regex: false,
            smart: false
          },
          paging: true,
          info: true,
          columnDefs: [{ visible: false, targets: groupColumn }],
          //order: (self.tableProps && self.tableProps.order ? self.tableProps.order : []),
          //pageLength: (self.tableProps && self.tableProps.page ? self.tableProps.page.length : 10),
          //displayStart: (self.tableProps && self.tableProps.page ? self.tableProps.page.length * self.tableProps.page.page : 0),
          //search: (self.tableProps && self.tableProps.search ? self.tableProps.search : null),
          dom: 'lBfrtip',
          buttons: [
            {
              extend: 'csv',
              footer: false,
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5,6,7]
              }
            },
            {
              extend: 'excel',
              footer: false,
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5,6,7]
              }
            }
          ],
          drawCallback: function (settings) {
            var api = this.api();
            var rows = api.rows({ page: 'current' }).nodes();
            var last = null;

            api
              .column(groupColumn, { page: 'current' })
              .data()
              .each(function (group, i) {
                if (last !== group) {
                  const text = group
                  $(rows)
                    .eq(i)
                    .before('<tr class="group"><td colspan="6">' + text + '</td></tr>');
                  last = group;
                }
              });
          },
        });


        if (self.tableProps.order && self.tableProps.order.length) {
          self.tableObject.order(self.tableProps.order).draw()
        }

        if (self.tableProps.search) {
          self.tableObject.search(self.tableProps.search).draw()
        }

        $(`#${self.id}`).on('order.dt', function (e, settings) {
          if (self.tableObject.order()) {
            self.tableProps.order = self.tableObject.order()
          }
        });

        $(`#${self.id}`).on('search.dt', function () {
          self.tableProps.search = self.tableObject.search();
        });
      },
      editMovement(movementId) {
        eventBus.$emit(EDIT_MOVEMENT_EVENT, movementId)
      },
      cancelMovement(movementId) {
        eventBus.$emit(CANCEL_MOVEMENT_EVENT, movementId)
      }
    },
    mounted() {
    //  this.refresh()
    },
    beforeDestroy() {
      if (this.tableObject) {
        this.$nextTick(() => {
          this.tableObject.destroy();
        });
      }
    }
  }
</script>
