<template>
  <!--<div id="alert-shim-wrap" v-if="message">
    <div id="alert-shim" :class="{ type }">
      <p>
        {{ message }}
      </p>
    </div>
  </div>-->

  <div class="alert" v-bind:class="{'alert-danger': type == 'error', 'alert-success' : type == 'success'}">
    <template v-if="Array.isArray(message)">
      <ul>
        <li v-for="m in message">{{ m }}</li>
      </ul>
    </template>
    <template v-else>
      {{ message }}
    </template>
    <button type="button" class="close" aria-label="Close" @click.stop="onClose">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

</template>

<style scoped lang="scss">
  .alert {
    white-space: pre-wrap;
  }

  .close {
    float: none;
    position: absolute;
    right: 10px;
    top: 10px;
    margin-top: -10px; /* Needs to be half the height of the element (21/2) */
  }
  /*.alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%*/
  /*max-width: 56%;*/
  /*}*/

  /*#alert-shim-wrap #alert-shim.error {
    background: #97002e
  }

  #alert-shim-wrap #alert-shim.success {
    background: #1a2c11
  }*/
</style>

<script>

  import { ALERT_TYPE_ERROR } from '@/utils/constants'

  export default {
    name: 'app-alert',
    props: {
      type: {
        type: String,
        required: false,
        default: ALERT_TYPE_ERROR
      },
      message: {
        required: true
      }
    },
    watch: {
      message: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.$nextTick(() => {
              var alert = document.querySelectorAll(
                "div.alert");
              $('html, body').animate({
                scrollTop: $(alert[0]).offset().top - 20
              }, 500);
            })
          }
        }
      }
    },
    methods: {
      onClose() {
        this.$emit('alertclose')
      }
    }
  }
</script>
