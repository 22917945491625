<script>

  import { ServerValidationError, AppError } from '@/utils/errors'
  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'

  export default {
    data() {
      return {
        errors: []
      }
    },
    methods: {
      handleError(error) {
        const self = this
        //if (error instanceof ServerValidationError) {
        //  this.errors = [...error.errorList]
        //  return
        //}
        //var errorMessage = ErrorMessages.getErrorMessageByErrorCode(error.appResultCode)
        //this.showErrorMessage(errorMessage)
        if (error instanceof ServerValidationError) {
          for (const e of error.errorList) {
            //self.errors.push({
            //  title: 'validation error',
            //  message: e
            //})
            this.showErrorMessage(e)
          }
        }
        //else if (error instanceof AppError) {
        //  //self.errors.push({
        //  //  title: 'server error',
        //  //  message: error.message
        //  //})
        //  this.showErrorMessage(error.message)
        //}
        else if (error.message != null) {
          //self.errors.push({
          //  title: 'Server error',
          //  message: error.message
          //})
          this.showErrorMessage(error.message)
        }
        else{
          this.showErrorMessage(error)
        }
      }
    }
  };</script>
